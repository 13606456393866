import React, { useState }  from "react"
import axios from "axios";
import { Link } from "gatsby"
import styled from 'styled-components'
import { Button } from '../elements'

const ContactForm = () => {
  
  const PButton = styled(Button)`
  float: right;
  margin-top: 2em;
  background: #fff;
  color: black;
  :hover {
    background: black;
    color: white;
    transition: 0.6s ease 0s !important;
    cursor: pointer;
    `

    const FormStyle = styled.div`
    form label {
      visibility: hidden;
    }
    
    form input, textarea {
      color: white;
      margin: 0;
      padding:5px 5px;
      width:100%;
      -webkit-appearance: none;
      outline: none;
      background: #263328;
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid #fff ;
      box-sizing: border-box;
      -webkit-box-sizing:border-box;
         -moz-box-sizing:border-box;
              box-sizing:border-box;
        -webkit-transition:0.2s ease all;
         -moz-transition:0.2s ease all;
          -ms-transition:0.2s ease all;
           -o-transition:0.2s ease all;
              transition:0.2s ease all;
  
      ::placeholder {
          color: #a0ada2;
          opacity: 0.6;
          font-weight: 300;
      }

      .form-control .input-note {
        height: 10vh;
      }
`  
    
    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/24fce904-5353-4f41-b3f4-8593d9a56159",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thanks!", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };
    return (
        
    <FormStyle>
         <div className="col-md-8 mt-5">
            <form onSubmit={handleOnSubmit}>
            <div className="form-group">
                <label htmlFor="InputEmail1" required="required">Email address</label>
                <input type="email" name="email" className="form-control" id="InputEmail1" aria-describedby="emailHelp" placeholder="Enter email"/>
            </div> 
            <div className="form-group">
                <label htmlFor="InputName">Name</label>
                <input type="text" name="name" className="form-control" id="InputName" placeholder="Enter your name" required="required"/>
            </div>
            <div className="form-group">
                <label htmlFor="InputTel">Phone Number</label>
                <input type="tel" name="tel" className="form-control" id="InputTel" placeholder="Enter your number" required="required"/>
            </div>
            <div className="form-group">
                <label htmlFor="InputNote">Note</label>
                <textarea type="text" name="note" className="form-control input-note" id="InputNote" placeholder="Note"/>
            </div>

            <PButton py={2} px={6} type="submit" className="btn btn-primary"  disabled={serverState.submitting}>
                Submit
            </PButton>

            {serverState.status && (
                <p className={!serverState.status.ok ? "errorMsg" : "Something went wrong : ( Please try again"}>
                {serverState.status.msg} We'll be in touch as soon as possible. 
                In the meantime, be sure to view our most recent <span><i><a class="contact-form-message-links" href="/projects">projects</a></i></span> or see what we've been up to on <span><i><a class="contact-form-message-links" href="/instagram">Instagram</a></i></span>.
                </p>
            )}
            </form>
        </div>
      </FormStyle>  
     
    );
  };
  
  export default ContactForm;