import React from 'react'
import { config, useSpring, animated } from 'react-spring'
import Layout from '../components/layout'
import ContactForm from '../components/contact'
import GridAbout from '../components/grid-about'
import styled from 'styled-components'
import Map from '../components/map'
import { AnimatedBox, Box } from '../elements'
import SEO from '../components/SEO'

const Area = styled(animated.div)`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-auto-rows: 35vw;

  a {
    color: #263328;
    text-decoration: none;
    &:hover {
      color: #a0ada2;
    }
  }
  a.contact-form-message-links {
    color: #fff;
    text-decoration: none;
    &:hover {
      color: #a0ada2;
    }
  }
  p {
    font-size: 12px;
  }
  @media (max-width: ${props => props.theme.breakpoints[3]}) {
    grid-template-columns: 2fr 1fr;
    grid-auto-rows: 40vw;
  }
  form {
    padding-right: 20%;
  }
  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: 2fr 1fr;
    grid-auto-rows: 50vh;
  }
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    grid-template-columns: 1fr;
    grid-auto-rows: 50vh 35vh;
  }
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    grid-template-columns: 1fr;
    grid-auto-rows: 65vh 35vh;
  }

`
const MapTitle = styled.div`
  position: absolute; 
  letter-spacing: 0.14em;
  color: white;
  font-weight: 300;
  transform: rotate(-90deg);
  transform-origin: left, top;
  -ms-transform: rotate(-90deg);
  -ms-transform-origin:left, top;
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin:left, top;
  
  span {
    color: #a0ada2;
    font-weight: 300;
  }
`

const Privacy = styled.div`
  position: absolute;
  margin: 0;
  font-size: 10px;
  bottom: 0.1em;
  color: #564f62;
  font-weight: 300;
  span {
    font-weight: 400;
    font-size: 12px;
  }
  p {
    line-height: 0.1em;
  }
  a {
    color: grey;
    text-decoration: underline;
    text-decoration-style: dotted;
  }
  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    position: relative;
  }
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    position: relative; 
  }
`

const ContactContent = `
AS Creative Studio
Arran Street East
Dublin, IRELAND

<i>Tel </i><a href="tel:+353 1 9609404">+353 1 9609404</a>
<a href="mailto:hello@ascreative.studio">hello@ascreative.studio</a>
///////////////////////////////////
`
const contactDescription = ContactContent.split(`\n\n`).map(paragraph => `<p>${paragraph.replace(/\n/g, `<br>`)}</p>`).join(``)

const Contact = () => {
  const pageAnimation = useSpring({
    config: config.slow, delay: 20,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const mapAnimation = useSpring({
    config: config.stiff, 
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const privacyAnimation = useSpring({
    config: config.slow, delay: 800,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout color="#263328">
      <SEO title="Contact | ASC Studio" desc="Get in touch.." />
      <Area>
      <GridAbout> 
      <AnimatedBox bg="#263328" color="white" py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 11, 13, 15]}>
        <h2>Get in touch.</h2>
        <ContactForm />
      </AnimatedBox>
      </GridAbout>
      <GridAbout>
      <AnimatedBox bg="#fff" color="#263328" py={[10]} px={[6, 6, 8, 6, 8, 11, 13, 15]}>
      <p dangerouslySetInnerHTML={{ __html: contactDescription }} />
        <AnimatedBox style={privacyAnimation}>
          <Privacy>
            <p>Registered in Ireland</p>
            <p><a href="/">Privacy Policy</a>, <a href="/">Terms of Service</a></p>
          </Privacy>
        </AnimatedBox>
      </AnimatedBox>
      </GridAbout>
      </Area>
      <AnimatedBox style={mapAnimation}>
      <Box bg="#a0ada2" style={{ fill: '#263328', stroke: '#a0ada2', strokeWidth: '0.01em'}} >
      <MapTitle>DUBLIN <span>CITY</span></MapTitle>
      <Map />
      </Box>
      </AnimatedBox>

    </Layout>
  )
}

export default Contact
